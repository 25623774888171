import React,{ useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


import mytask from './../../Images/newtask.png';

import { Navigate, useHistory } from 'react-router-dom';
import { Button } from "reactstrap";
// import { useStyles } from "./../../Utilities/CSSUtilities";
import axios from "axios";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import base_url from "../../api/bootapi";
import moment from "moment";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useToasts } from 'react-toast-notifications';
import { customStyles, useStyles } from "./../../Utilities/CSSUtilities";
import { 
  setStopped,
  logout,
  selectEmployee,
 } from "../../components/features/empSlice";

const drawerWidth = 240;



const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function NewDash({ Comp, sidebarvalue}) {
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const dispatch = useDispatch();
  let history = useHistory();
  const employeeData = useSelector(selectEmployee);
  const { addToast } = useToasts();

  useEffect( () => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    setTimeout(() => {
      getPermissions();
    }, 3000);
  },[]);

 const getPermissions = () =>{
  let value = "JWT " + localStorage.getItem("JWTToken");
  }



  const theme = useTheme();
  
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
 
 



  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];

  const handleDrawerOpen = () => {
    setOpen(true);
  };





  const handleDrawerClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
 
  const [openAccountMenu, setOpenAccountMenu] = useState(null);
  const handleAccountOpen = (event) => setOpenAccountMenu(event.currentTarget);
  const handleAccountClose = () => setOpenAccountMenu(null);
  const handleLogout = () => {
    dispatch(logout());
    history.push(`/`);
    addToast(`${employeeData.emp_name} Logged Out.`, {
      appearance: "success",
      autoDismiss: true,
    });
  }











  return (
    <Box sx={{ display: 'flex' }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{backgroundColor:"#071A52"}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box display='flex' flexGrow={1}>
            {/* whatever is on the left side */}
            <Typography variant="h6" noWrap component="div">
            Amcrest Warranty
            </Typography>
          </Box>
          {/* whatever is on the right side */}

          <AccountCircleIcon onClick={handleAccountOpen}/> 

          <Menu
            id="simple-menu"
            anchorEl={openAccountMenu}
            keepMounted
            open={Boolean(openAccountMenu)}
            onClose={handleAccountClose}
          >         
            <MenuItem
              onClick={handleLogout}
            >Logout</MenuItem>
          </Menu>
          
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
        <img alt="Odisoft_logo" src="https://amcrest.com/static/frontend/Amcrest/amcrest/en_US/images/logo.png" style={{width:"150px" ,margin:"0px"}}/>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
            {/* <ListItemButton
              onClick={()=>navigate("/")}
              key="Dashboard"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <img src={Home} style={{width:"35px",height:"35px"}} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton> */}


            <ListItemButton
              onClick={()=>history.push("/")}
              key="MyTasks"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <img src={mytask} style={{width:"35px",height:"35px"}}/>
              </ListItemIcon>
              <ListItemText primary="Warranty Certificate" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>

        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Comp/>
      </Box>
    </Box>
  );
}
