import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory, useParams } from "react-router-dom";
import { customStyles, useStyles } from "../../Utilities/CSSUtilities";
import moment from "moment";
import Base64Downloader from "common-base64-downloader-react";
import Swal from "sweetalert2";
import base_url from '../../api/bootapi';
import { useDispatch, useSelector } from "react-redux";
import { logout, selectEmployee } from "../../components/features/empSlice";
import { useToasts } from "react-toast-notifications";

function Certificate() {
  useEffect(() => {
    getIp();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [backdropopen, setbackdropopen] = useState(false);


  const history = useHistory();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [ip, setIp] = useState();
  const [open, setOpen] = useState(false);
  const [basePDF, setBasePDF] = useState();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0px",
      minWidth: "700px",
      transform: "translate(-50%, -50%)",
    },
  };

  const onsubmit = (d) => {
    setbackdropopen(true);
    console.log(d);
    let sent_to = d.email_address;
    let ip_address = ip;
    let customer_name = d.name;
    let approved_by = "hrushikesh";
    let order_id = d.order_id;
    let serial_no = d.serial;
    let purchase_date = moment(d.purchasedt).format("YYYY-MM-DD");
    // let warranty_expiration_date = d.warrantydt

    let data = {
      sent_to,
      ip_address,
      customer_name,
      approved_by,
      order_id,
      serial_no,
      purchase_date,
    };
    console.log(data);
    post(data);
  };

  const getIp = () => {
    axios.get("https://geolocation-db.com/json/").then((res) => {
      console.log(res);
      let ipaddress = res.data.IPv4;
      setIp(res.data.IPv4);
    });
  };

  const post = (data) => {
    axios
      .post(`${base_url.api1}/document/certificate`, data, {})
      .then(
        (response) => {
          if (response.status === 200) {
            console.log(response);
            setbackdropopen(false);
            console.log(response.data.data.certificate_file);
            // Swal.fire('Certificate Created', 'You clicked the button!', 'success')
            document.getElementById("myform").reset();
            setOpen(true);
            setBasePDF(
              `data:application/pdf;base64,${response.data.data.certificate_file}`
            );
          } else {
            setbackdropopen(false);
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: "Certficate Already generated for this OrderId",
              showCloseButton: true,
              showConfirmButton: false,
            });
            document.getElementById("myForm").reset();
          }
        },
        (error) => {
          if (error.response.status == "401") {
            dispatch(logout());
            history.push("/");
            addToast(` Session Expired.`, {
              appearance: "error",
              autoDismiss: true,
            });
          }
          else {
            setbackdropopen(false);
            document.getElementById("myform").reset();
            console.log(JSON.stringify(error.message));
          }
        }
      );
  };

  const goBack = () => {
    history.push("/");
  };
  // const base64PDF =
  //   'data:application/pdf;base64,JVBERi0xLjcKCjEgMCBvYmogICUgZW50cnkgcG9pbnQKPDwKICAvVHlwZSAvQ2F0YWxvZwogIC9QYWdlcyAyIDAgUgo+PgplbmRvYmoKCjIgMCBvYmoKPDwKICAvVHlwZSAvUGFnZXMKICAvTWVkaWFCb3ggWyAwIDAgMjAwIDIwMCBdCiAgL0NvdW50IDEKICAvS2lkcyBbIDMgMCBSIF0KPj4KZW5kb2JqCgozIDAgb2JqCjw8CiAgL1R5cGUgL1BhZ2UKICAvUGFyZW50IDIgMCBSCiAgL1Jlc291cmNlcyA8PAogICAgL0ZvbnQgPDwKICAgICAgL0YxIDQgMCBSIAogICAgPj4KICA+PgogIC9Db250ZW50cyA1IDAgUgo+PgplbmRvYmoKCjQgMCBvYmoKPDwKICAvVHlwZSAvRm9udAogIC9TdWJ0eXBlIC9UeXBlMQogIC9CYXNlRm9udCAvVGltZXMtUm9tYW4KPj4KZW5kb2JqCgo1IDAgb2JqICAlIHBhZ2UgY29udGVudAo8PAogIC9MZW5ndGggNDQKPj4Kc3RyZWFtCkJUCjcwIDUwIFRECi9GMSAxMiBUZgooSGVsbG8sIHdvcmxkISkgVGoKRVQKZW5kc3RyZWFtCmVuZG9iagoKeHJlZgowIDYKMDAwMDAwMDAwMCA2NTUzNSBmIAowMDAwMDAwMDEwIDAwMDAwIG4gCjAwMDAwMDAwNzkgMDAwMDAgbiAKMDAwMDAwMDE3MyAwMDAwMCBuIAowMDAwMDAwMzAxIDAwMDAwIG4gCjAwMDAwMDAzODAgMDAwMDAgbiAKdHJhaWxlcgo8PAogIC9TaXplIDYKICAvUm9vdCAxIDAgUgo+PgpzdGFydHhyZWYKNDkyCiUlRU9G'

  // simple pdf download
  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={backdropopen}
        style={{ zIndex: "1" }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="containerRegister">
        <Modal
          isOpen={open}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => setOpen(false)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div>
            <div
              style={{
                backgroundColor: "#7DC280",
                padding: "15px 15px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={{ padding: "0px", margin: "0px" }}></p>

              <p
                style={{
                  padding: "0px",
                  margin: "0px",
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(false)}
              >
                x
              </p>
            </div>
            <div
              style={{
                padding: "60px 40px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  padding: "0px",
                  margin: "0px",
                  fontSize: "1.6rem",
                  fontWeight: "600",
                  color: "#023154",
                }}
              >
                Certificate sent to your mail Successfully
              </p>
              <Base64Downloader
                base64={basePDF}
                downloadName="Amcrest Certificate"
                // onClick={() => setOpen(false)}
                onDownloadSuccess={() => {
                  Swal.fire({
                    title: "Certificate Downloaded Sucessfully",
                    icon: "success",
                  });
                  setOpen(false);
                }}
                style={{
                  padding: "15px 40px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "#7DC280",
                  color: "#fff",
                  outline: "none",
                  border: "none",
                  marginTop: "40px",
                  fontSize: "1.2rem",
                  minWidth: "250px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Download
              </Base64Downloader>
            </div>
          </div>
        </Modal>

        <div className="image">
          <img
            src="https://amcrest.com/static/frontend/Amcrest/amcrest/en_US/images/logo.png"
            alt="logo"
          />
        </div>

        <div className="form-container">
          <h1
            style={{
              textAlign: "center",
              margin: "10px 0px 20px",
              color: "#023154",
              fontWeight: "400",
            }}
          >
            WARRANTY CERTIFICATE
          </h1>
          <form id="myform" onSubmit={handleSubmit(onsubmit)}>
            <div>
              <p className="all-p">
                Customer Name<span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className="all-input"
                name="name"
                {...register("name", {
                  required: "Required",
                })}
              />
              {errors.name && (
                <p className="marginzero">Customer name is required.</p>
              )}
            </div>
            <div>
              <p className="all-p">
                Email<span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="email"
                className="all-input"
                name="email_address"
                {...register("email_address", {
                  required: "Required",
                })}
              />
              {errors.email_address && (
                <p className="marginzero">Email Address is required.</p>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="all-p">
                  Order ID<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  className="all-input"
                  name="title"
                  {...register("order_id", {
                    required: "Required",
                  })}
                />
                {errors.order_id && (
                  <p className="marginzero">Order Id number is required.</p>
                )}
              </div>
              <div>
                <p className="all-p">
                  Skus/Serial No<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  className="all-input"
                  name="email_address"
                  {...register("serial", {
                    required: "Required",
                  })}
                />
                {errors.serial && (
                  <p className="marginzero">Serial number is required.</p>
                )}
              </div>
            </div>

            <div>
              <p className="all-p">
                Purchase Date<span style={{ color: "red" }}>*</span>
              </p>
              <input
                className="all-input"
                type="date"
                {...register("purchasedt", {
                  required: "Required",
                })}
              />
              {errors.purchasedt && (
                <p className="marginzero">Purchase Date is required.</p>
              )}
            </div>

            {/* <div>
              <p className="all-p">
                Warranty Date<span style={{color: 'red'}}>*</span>
              </p>
              <input
                type="date"
                className="all-input"
                {...register('warrantydt', {
                  required: 'Required',
                })}
              />
              {errors.warrantydt && (
                <p className="marginzero">Warranty Date is required.</p>
              )}
            </div> */}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "30px",
              }}
            >
              <a
                className="btn-reset"
                onClick={goBack}
              >
                Go Back
              </a>
              <button className="btn-submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Certificate;
