import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Pagination from "@mui/material/Pagination";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import axios from "axios";
import base_url from "../../api/bootapi";
import { customStyles, useStyles } from "./../../Utilities/CSSUtilities";
import PasswordStrengthBar from "react-password-strength-bar";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectEmployee } from "../../components/features/empSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useToasts } from "react-toast-notifications";
import FormControl from "@mui/material/FormControl";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import Base64Downloader from 'common-base64-downloader-react';
import Swal from 'sweetalert2'
import { CSVLink, CSVDownload } from "react-csv";

const WarrantyCertificate = (props) => {

    const customStylestwo = {
        content: {
            width: "700px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };

    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [dataForDownload, setDataForDownload] = useState([]);
    const [bDownloadReady, setDownloadReady] = useState(false);
    const csvLink = useRef();

    useEffect(() => {
        if (csvLink && csvLink.current && bDownloadReady) {
            csvLink.current.link.click();
            setDownloadReady(false);
        }
    }, [bDownloadReady]);

    //console.log(params);
    const jwtToken = "JWT " + localStorage.getItem("JWTToken");
    const [certificateData, setCertificateData] = useState([]);
    const history = useHistory();
    const [searchStatus, setSearchStatus] = useState();
    const [datefilter, setdatefilter] = useState();
    const [purchasedatefilter, setpurchasedatefilter] = useState();
    const [expirydatefilter, setexpirydatefilter] = useState();
    const [backdropOpen, setBackdropOpen] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [updatePassModel, setUpdatePassModel] = React.useState(false);
    const [inputtype, setinputtype] = useState("password");
    const employeData = useSelector(selectEmployee);
    const [users, setUser] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [paginationPageNo, setPaginationPageNo] = useState(1);
    const [getPageNo, setGetPageNo] = useState();
    const [rows, setRows] = React.useState(50);
    const [sortState, setsortState] = useState("email");
    const [sortStatetwo, setsortStatetwo] = useState("customer_name");
    const [orderState, setorderState] = useState("ASC");
    const [byState, setbyState] = useState("created_date");
    const [direction, setdirection] = useState("Desc");
    const [userStatus, setUserStatus] = useState(1);
    const [totalcount, setTotalCount] = useState();
    const [countNumber, setCountNumber] = useState();
    const [numberOfElements, setNumberOfElements] = useState();
    const [selectedDelete, setSelectedDelete] = useState([]);
    const [datesrange, setdatesrange] = useState();
    const [display1, setdisplay] = useState("inline");

    const [createdStartDate, setCreatedStartDate] = useState('');
    const [createdEndDate, setCreatedEndDate] = useState('');

    const { addToast } = useToasts();

    const closeModal = () => {
        setUpdatePassModel(false);
    };

    const updatePassword = () => {
        setUpdatePassModel(true);
    };





    useEffect(() => {
        downloadAllIssueCertificate();
        getAllUserList(1);
        window.addEventListener("storage", function (e) {
            if (e.key === null) {
                dispatch(logout());
                history.push("/");
            }
        });
        return () => {
            setCertificateData();
        };
    }, [rows]);




    const getAllUserList = (value1) => {
        setBackdropOpen(true);
        // console.log(value1);
        axios
            .get(
                `${base_url.api1}/document/certificates_list?&page=` + value1 + `&size=` + rows,
                {
                    //   headers: {
                    //     Authorization: jwtToken,
                    //   },
                }
            )
            .then(
                (response) => {
                    console.log(response);
                    setCertificateData(response.data.Certificates);
                    console.log(certificateData);
                    setGetPageNo(response.data.total_pages);
                    //console.log(response.data.total_pages);
                    setTotalCount(response.data.total_count);
                    setCountNumber(value1 - 1);
                    setNumberOfElements(response.data.Certificates.length);
                    //console.log(response.data.Certificates.length);
                    setSelectedDelete([]);
                    setdatefilter(false);
                    setpurchasedatefilter(false);
                    setexpirydatefilter(false);
                    setSearchStatus(false);
                    setBackdropOpen(false);
                    document.getElementById("myForm").reset();
                },
                (error) => {
                    //   setdatefilter(false);
                    //   setBackdropOpen(false);
                    //   setCertificateData();
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        console.log(JSON.stringify(error.response.data));
                    }
                }
            );
    };

    const [getId, setId] = useState();
    const showPdfFile = (cert) => {
        setId(cert.id);
        console.log("helllo from Trans", cert.id);
        dnloadCertificate(cert.id);
    };

    function downloadPDF(pdf) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "AmcrestWarrenty.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const [dnloadpdf, setdnloadpdf] = useState();
    const dnloadCertificate = (getId) => {
        console.log("Hello dnload", getId);
        axios
            .get(
                `${base_url.api1}/document/certificate?id=` + getId,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    console.log(response);
                    downloadPDF(response.data.Certificate.certificate_file);
                    console.log(response.data.Certificate.certificate_file);
                    setdnloadpdf(response.data.Certificate.certificate_file);
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        console.log(JSON.stringify(error.response.data));
                    }
                }
            );
    };

    const downloadAllIssueCertificate = () => {

        axios
            .get(
                `${base_url.api1}/document/certificates_data_list`,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    console.log("response---------->", response);
                    setDataForDownload(response.data.Certificates_csv)
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    const handleAction = (actionType) => {
        if (actionType === 'DOWNLOAD') {
            let data = {
                sortby: sortStatetwo,
                Order: orderState,
                createdStartDate: createdStartDate,
                createdEndDate: createdEndDate,
                byState: byState,
            };
            axios
                .get(`${base_url.api1}/document/certificates_data_list?` + `sortby=` + data.sortby + `&Order=` + data.Order +
                    `&from=` + data.createdStartDate + `&to=` + data.createdEndDate + `&by=` + data.byState, {
                    headers: {
                        Authorization: jwtToken,
                    },
                })
                .then(
                    (response) => {
                        console.log(response.data.Certificates_csv);
                        setDataForDownload(response.data.Certificates_csv);
                        setDownloadReady(true);
                    },
                    (error) => {
                        setDataForDownload();
                        setDownloadReady(true);
                    }
                );
        }
    };

    const sortUser = (e) => {
        setsortState(e.target.id);
        console.log();
        if (direction == "Desc") {
            setdirection("ASC");
        } else if (direction == "ASC") {
            setdirection("Desc");
        }
        let sortby = e.target.id;
        setBackdropOpen(true);
        axios
            .get(
                `${base_url.api1}/document/certificates_list?&sortby=` + sortby + `&Order=` + direction + `&page=` + pageNo,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    setCertificateData(response.data.Certificates);
                    document.getElementById("myForm").reset();
                    setGetPageNo(response.data.total_pages);
                    //console.log(response.data.total_pages);
                    setTotalCount(response.data.total_count);
                    setCountNumber(pageNo - 1);
                    setNumberOfElements(response.data.Certificates.length);
                    //console.log(response.data.Certificates.length);
                    setSelectedDelete([]);
                    setdatefilter(false);
                    setpurchasedatefilter(false);
                    setexpirydatefilter(false);
                    setSearchStatus(false);
                    setBackdropOpen(false);
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        console.log(JSON.stringify(error.response.data));
                        setCertificateData();
                        setBackdropOpen(false);
                    }
                }
            );
    };

    const sortUsercsv = (e) => {
        setsortStatetwo(e.target.value);
        let sortby = e.target.value;
        let data = {
            Order: orderState,
            createdStartDate: createdStartDate,
            createdEndDate: createdEndDate,
            byState: byState,
        };
        axios
            .get(
                `${base_url.api1}/document/certificates_data_list?` + `sortby=` + sortby + `&Order=` + data.Order +
                `&from=` + data.createdStartDate + `&to=` + data.createdEndDate + `&by=` + data.byState,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    setDataForDownload(response.data.Certificates_csv);
                    document.getElementById("myForm").reset();
                    setBackdropOpen(false);
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        console.log(JSON.stringify(error.response.data));
                        setCertificateData();
                        setBackdropOpen(false);
                    }
                }
            );
    };

    const orderUsercsv = (e) => {
        setorderState(e.target.value);
        let orderby = e.target.value;
        let data = {
            sortby: sortStatetwo,
            createdStartDate: createdStartDate,
            createdEndDate: createdEndDate,
            byState: byState,
        };
        axios
            .get(
                `${base_url.api1}/document/certificates_data_list?` + `sortby=` + data.sortby + `&Order=` + orderby +
                `&from=` + data.createdStartDate + `&to=` + data.createdEndDate + `&by=` + data.byState,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    setDataForDownload(response.data.Certificates_csv);
                    document.getElementById("myForm").reset();
                    setBackdropOpen(false);
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        console.log(JSON.stringify(error.response.data));
                        setCertificateData();
                        setBackdropOpen(false);
                    }
                }
            );
    };

    const ByUsercsv = (e) => {
        setbyState(e.target.value);

        let byfilter = e.target.value;
        let data = {
            sortby: sortStatetwo,
            Order: orderState,
            createdStartDate: createdStartDate,
            createdEndDate: createdEndDate,
        };
        axios
            .get(
                `${base_url.api1}/document/certificates_data_list?` + `sortby=` + data.sortby + `&Order=` + data.Order +
                `&from=` + data.createdStartDate + `&to=` + data.createdEndDate + `&by=` + byfilter,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    setDataForDownload(response.data.Certificates_csv);
                    document.getElementById("myForm").reset();
                    setBackdropOpen(false);
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        console.log(JSON.stringify(error.response.data));
                        setCertificateData();
                        setBackdropOpen(false);
                    }
                }
            );
    };

    const closedModal = () => {
        setDelete(false);
    };


    const [DeleteId, setDeleteId] = useState();
    const [DeleteName, setDeleteName] = useState();
    const [deleteOpen, setDelete] = React.useState(false);
    const deleteUser = (elem, cert) => {
        console.log(cert);
        setDeleteId(cert.id);
        setDeleteName(cert.first_name + " " + cert.last_name);
        setDelete(true);
    };
    const deleteCurrentCategory = () => {
        axios
            .delete(
                `${base_url.api1}/document/certificate?` + `&id=` + DeleteId,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    setBackdropOpen(false);
                    setDelete(false);
                    getAllUserList(pageNo);
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        setBackdropOpen(false);
                        console.log(JSON.stringify(error.response.data));
                        setCertificateData(null);
                    }
                }
            );
    };

    const [mailerId, setMailerId] = useState();
    const [changeMailOpen, setChangeMailOpen] = React.useState(false);


    const createModalMail = (elem, cert) => {
        console.log(cert);
        setMailerId(cert.id);
        setChangeMailOpen(true);
    }
    const closeModalofMail = () => {
        setChangeMailOpen(false);
    };




    const createChangeMail = (data2) => {
        setBackdropOpen(true);
        let sent_to = data2.sent_to;
        let Id = mailerId;

        let data = {
            sent_to,
        };
        axios
            .put(`${base_url.api1}/document/certificate?id=` + Id, data, {
            })
            .then(
                (response) => {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Thank You .",
                        text: "Mail Changed Successfully",
                        showCloseButton: true,
                        showConfirmButton: false,
                    });
                    setBackdropOpen(false);
                    setChangeMailOpen(false);
                    getAllUserList(1);
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            showCloseButton: true,
                            showConfirmButton: false,
                        });
                        console.log(error);
                        document.getElementById("addform").reset();
                        setBackdropOpen(false);
                        setChangeMailOpen(false);
                        getAllUserList(1);
                    }
                }
            );
    };

    const ResentMail = (e, cert) => {
        setBackdropOpen(true);
        console.log(cert.id);
        let Id = cert.id;
        let data = {
            Id: Id
        };
        axios
            .post(`${base_url.api1}/document/certificate_resend`, data, {
            })
            .then(
                (response) => {
                    setBackdropOpen(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Thank You .",
                        text: "Certifiacte Sent Sucessfully to your Mail",
                        showCloseButton: true,
                        showConfirmButton: false,
                    });
                    document.getElementById("addform").reset();
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        console.log(error);
                        setBackdropOpen(false);
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            showCloseButton: true,
                            showConfirmButton: false,
                        });
                        document.getElementById("addform").reset();
                    }
                }
            );
    };

    const handlepage = (event, value) => {
        console.log(value);
        setPaginationPageNo(value);
        setPageNo(value);
        if (searchStatus) {
            submitPageSearch(value);
        } else {
            getAllUserList(value);
        }
    };

    const [datevalue, setdatevalue] = useState("Click to Open");
    const handleApply = (event, picker) => {
        setBackdropOpen(true);
        setdatevalue(
            `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
                "DD-MM-YYYY"
            )}`
        );
        setPageNo(0);
        setPaginationPageNo(1);
        let data = {
            from: picker.startDate.format("YYYY-MM-DD"),
            to: picker.endDate.format("YYYY-MM-DD"),
            //sortby: sortState,
            // Order: direction,
        };
        setdatesrange(data);
        axios
            .get(
                `${base_url.api1}/document/certificates_list?` + `&page=` + 1 + `&size=` + rows + `&from=` + data.from + `&to=` + data.to,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    setCertificateData(response.data.Certificates);
                    setGetPageNo(response.data.total_pages);
                    //console.log(response.data.total_pages);
                    setTotalCount(response.data.total_count);
                    //setCountNumber(value1-1);
                    setNumberOfElements(response.data.Certificates.length);
                    setSelectedDelete([]);
                    setSearchStatus(false);
                    setBackdropOpen(false);
                    setdatefilter(true);
                    setpurchasedatefilter(false);
                    setexpirydatefilter(false);
                    document.getElementById("myForm").reset();
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        setCertificateData();
                        console.log(JSON.stringify(error.response.data));
                        setdatefilter(true);
                        setpurchasedatefilter(false);
                        setexpirydatefilter(false);
                        setBackdropOpen(false);
                    }
                }
            );
    };

    const [dateval, setdateval] = useState("Click to Open");
    const handleApplycsv = (event, picker) => {
        setdatevalue(
            `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
                "DD-MM-YYYY"
            )}`
        );
        let data = {
            sortby: sortStatetwo,
            Order: orderState,
            createdStartDate: picker.startDate.format("YYYY-MM-DD"),
            createdEndDate: picker.endDate.format("YYYY-MM-DD"),
            byState: byState,
        };
        setdatesrange(data);
        setCreatedStartDate(picker.startDate.format("YYYY-MM-DD"));
        setCreatedEndDate(picker.endDate.format("YYYY-MM-DD"));
        axios
            .get(
                `${base_url.api1}/document/certificates_data_list?` + `sortby=` + data.sortby + `&Order=` + data.Order +
                `&from=` + data.createdStartDate + `&to=` + data.createdEndDate + `&by=` + data.byState,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    setDataForDownload(response.data.Certificates_csv);
                    setBackdropOpen(false);
                    document.getElementById("myForm").reset();
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        setCertificateData();
                        console.log(JSON.stringify(error.response.data));
                        setBackdropOpen(false);
                    }
                }
            );
    };

    const [purchaseDateValue, setPurchaseDateValue] = useState("Click to Open");
    const handlePurchaseApply = (event, picker) => {
        setBackdropOpen(true);
        setPurchaseDateValue(
            `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
                "DD-MM-YYYY"
            )}`
        );
        setPageNo(0);
        setPaginationPageNo(1);
        let data = {
            from: picker.startDate.format("YYYY-MM-DD"),
            to: picker.endDate.format("YYYY-MM-DD"),
            //sortby: sortState,
            // Order: direction,
        };
        setdatesrange(data);
        axios
            .get(
                `${base_url.api1}/document/certificates_list?` + `&page=` + 1 + `&size=` + rows + `&from=` + data.from + `&to=` + data.to + `&by=purchase_date`,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    setCertificateData(response.data.Certificates);
                    setGetPageNo(response.data.total_pages);
                    //console.log(response.data.total_pages);
                    setTotalCount(response.data.total_count);
                    //setCountNumber(value1-1);
                    setNumberOfElements(response.data.Certificates.length);
                    setSelectedDelete([]);
                    setSearchStatus(false);
                    setBackdropOpen(false);
                    setdatefilter(false);
                    setpurchasedatefilter(true);
                    setexpirydatefilter(false);
                    document.getElementById("myForm").reset();
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        setCertificateData();
                        console.log(JSON.stringify(error.response.data));
                        setdatefilter(false);
                        setpurchasedatefilter(true);
                        setexpirydatefilter(false);
                        setBackdropOpen(false);
                    }
                }
            );
    };

    const [expiryDateValue, setexpiryDateValue] = useState("Click to Open");
    const handleExpiryDate = (event, picker) => {
        setBackdropOpen(true);
        setexpiryDateValue(
            `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
                "DD-MM-YYYY"
            )}`
        );
        setPageNo(0);
        setPaginationPageNo(1);
        let data = {
            from: picker.startDate.format("YYYY-MM-DD"),
            to: picker.endDate.format("YYYY-MM-DD"),
            //sortby: sortState,
            // Order: direction,
        };
        setdatesrange(data);
        axios
            .get(
                `${base_url.api1}/document/certificates_list?` + `&page=` + 1 + `&size=` + rows + `&from=` + data.from + `&to=` + data.to + `&by=warranty_expiration_date`,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    setCertificateData(response.data.Certificates);
                    setGetPageNo(response.data.total_pages);
                    //console.log(response.data.total_pages);
                    setTotalCount(response.data.total_count);
                    //setCountNumber(value1-1);
                    setNumberOfElements(response.data.Certificates.length);
                    setSelectedDelete([]);
                    setSearchStatus(false);
                    setBackdropOpen(false);
                    setdatefilter(false);
                    setpurchasedatefilter(false);
                    setexpirydatefilter(true);
                    document.getElementById("myForm").reset();
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        setCertificateData();
                        console.log(JSON.stringify(error.response.data));
                        setdatefilter(false);
                        setpurchasedatefilter(false);
                        setexpirydatefilter(true);
                        setBackdropOpen(false);
                    }
                }
            );
    };

    const [searchData, setSearchData] = useState();
    const submitSearch = (e, value) => {
        setdisplay("none");
        setPageNo(1);
        setSearchStatus(true);
        let srchdata = e.target[0].value.trim();
        setSearchData(srchdata);
        console.log(srchdata);
        setBackdropOpen(true);
        axios
            .get(
                `${base_url.api1}/document/certificates_list?search=` + srchdata,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    setCertificateData(response.data.Certificates);
                    setGetPageNo(response.data.total_pages);
                    setCountNumber(pageNo - 1);
                    setNumberOfElements(response.data.Certificates.length);
                    setTotalCount(response.data.total_count);
                    setBackdropOpen(false);
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        console.log(JSON.stringify(error.response.data));
                        setBackdropOpen(false);
                        setCertificateData();
                    }
                }
            );
    };

    const submitPageSearch = (value) => {
        setdisplay("none");
        setPageNo(value);
        setBackdropOpen(true);
        axios
            .get(
                `${base_url.api1}/myappapi/users/list?&page=` + value + `&size=` + rows + `&search=` + searchData,
                {
                    headers: {
                        Authorization: jwtToken,
                    },
                }
            )
            .then(
                (response) => {
                    setCertificateData(response.data.Certificates);
                    setGetPageNo(response.data.total_pages);
                    setCountNumber(value - 1);
                    setNumberOfElements(response.data.Certificates.length);
                    setTotalCount(response.data.total_count);
                    setBackdropOpen(false);
                },
                (error) => {
                    if (error.response.status == "401") {
                        dispatch(logout());
                        history.push("/");
                        addToast(` Session Expired.`, {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        console.log(JSON.stringify(error.response.data));
                        setBackdropOpen(false);
                        setCertificateData();
                    }
                }
            );
    };

    const createClient = () => {
        history.push("/register");
    };


    return (
        <div>
            <Modal
                isOpen={open}
                // onAfterOpen={afterOpenModal}
                shouldCloseOnOverlayClick={false}
                onRequestClose={() => setOpen(false)}
                style={customStylestwo}
                contentLabel="Example Modal"
            >
                <div>
                    <p style={{ textAlign: "center", fontSize: "1.8rem" }}>Export As</p>

                    <form>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "2rem",
                                gap: "2rem",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.7rem",
                                    flex: 1,
                                }}
                            >
                                <label style={{ fontSize: "1.2rem" }}>Sort by</label>
                                <select
                                    onChange={sortUsercsv}
                                    style={{ padding: "0.5rem 0.6rem" }}
                                >
                                    <option value="customer_name">Customer Name</option>
                                    <option value="sent_to">Sent To</option>
                                    <option value="serial_no">Serial No</option>
                                    <option value="created_date">Created Date</option>
                                    <option value="purchase_date">Purchase Date</option>
                                    <option value="warranty_expiration_date">Warranty Expiration Date</option>
                                    <option value="ip_address">Ip Address</option>
                                </select>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.7rem",
                                    flex: 1,
                                }}
                            >
                                <label style={{ fontSize: "1.2rem" }}>Order</label>
                                <select
                                    style={{ padding: "0.5rem 0.6rem" }}
                                    onChange={orderUsercsv}
                                >
                                    <option value="ASC">Ascending</option>
                                    <option value="Desc">Descending</option>
                                </select>
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                marginTop: "2rem",
                                gap: "2rem",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.7rem",
                                    flex: 1,
                                }}
                            >
                                <label style={{ fontSize: "1.2rem" }}>From</label>
                                <DateRangePicker
                                    onApply={handleApplycsv}
                                    style={{ width: "200px" }}
                                >
                                    <p
                                        style={{
                                            padding: "0.5rem 0.6rem",
                                            border: "0.1px solid black",
                                            margin: "0px",
                                        }}
                                    >
                                        {datevalue}
                                    </p>
                                </DateRangePicker>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.7rem",
                                    flex: 1,
                                }}
                            >
                                <label style={{ fontSize: "1.2rem" }}>Filter by</label>
                                <select
                                    style={{ padding: "0.5rem 0.6rem" }}
                                    onChange={ByUsercsv}
                                >
                                    <option value="created_date">Created Date</option>
                                    <option value="purchage_date">Purchage Date</option>
                                    <option value="waranty_expiration_date">
                                        Waranty Expiration Date
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div
                            style={{
                                marginTop: "2rem",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <button
                                type="submit"
                                style={{
                                    padding: "0.7rem 0.9rem",
                                    background: "#306030",
                                    color: "white",
                                    borderRadius: "8px",
                                    border: "none",
                                    outline: "none",
                                    fontSize: "1rem",
                                    marginRight: "0.5rem",
                                }}
                                onClick={(e) => handleAction('DOWNLOAD')}
                            >
                                Download
                            </button>

                            <button
                                onClick={() => setOpen(false)}
                                style={{
                                    padding: "0.7rem 0.9rem",
                                    background: "#ff4d4d",
                                    color: "white",
                                    borderRadius: "8px",
                                    border: "none",
                                    outline: "none",
                                    fontSize: "1rem",
                                }}
                            >
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className="commoncomponent_cardcss">
                <div style={{ margin: "5px" }}>
                    <Row>
                        <Col md={6}>
                            <CardText
                                className={classes.headtext}
                                style={{ marginTop: "-10px" }}
                            >
                                <h2>Certificate List</h2>
                            </CardText>
                        </Col>
                        <Col md={6}>
                            <Button
                                className={classes.createBtn}
                                color="primary"
                                style={{
                                    float: "right",
                                    marginTop: "-10px",
                                }}
                                onClick={createClient}
                            >
                                + Add
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={3}>
                            <label><strong> Created Date:</strong></label>
                            <DateRangePicker
                                onApply={handleApply}
                            >
                                <p className="datepickerrangevaluetext">{datevalue}</p>
                            </DateRangePicker>
                            {datefilter ? (
                                <div>
                                    <a
                                        className={classes.clrsrch}
                                        onClick={() => {
                                            setdisplay("inline");
                                            setdatevalue("Click to Open");
                                            setBackdropOpen(true);
                                            setdirection("Desc");
                                            setPageNo(1);
                                            getAllUserList(1);
                                        }}
                                    >
                                        Clear Filter
                                    </a>
                                </div>
                            ) : null}
                        </Col>
                        <Col md={3}>
                            <label><strong>Purchase Date:</strong></label>
                            <DateRangePicker
                                onApply={handlePurchaseApply}
                            >
                                <p className="datepickerrangevaluetext">{purchaseDateValue}</p>
                            </DateRangePicker>
                            {purchasedatefilter ? (
                                <div>
                                    <a
                                        className={classes.clrsrch}
                                        onClick={() => {
                                            setdisplay("inline");
                                            setPurchaseDateValue("Click to Open");
                                            setBackdropOpen(true);
                                            setdirection("Desc");
                                            setPageNo(1);
                                            getAllUserList(1);
                                        }}
                                    >
                                        Clear Filter
                                    </a>
                                </div>
                            ) : null}
                        </Col>
                        <Col md={3}>
                            <label><strong>Expiry Date:</strong></label>
                            <DateRangePicker
                                onApply={handleExpiryDate}
                            >
                                <p className="datepickerrangevaluetext">{expiryDateValue}</p>
                            </DateRangePicker>
                            {expirydatefilter ? (
                                <div>
                                    <a
                                        className={classes.clrsrch}
                                        onClick={() => {
                                            setdisplay("inline");
                                            setexpiryDateValue("Click to Open");
                                            setBackdropOpen(true);
                                            setdirection("Desc");
                                            setPageNo(1);
                                            getAllUserList(1);
                                        }}
                                    >
                                        Clear Filter
                                    </a>
                                </div>
                            ) : null}
                        </Col>
                        <Col md={3}>
                            <label><strong></strong></label>
                            <div className="clearsearchclass">
                                <form
                                    id="myForm"
                                    className="d-flex"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        submitSearch(e);
                                    }}
                                >
                                    <input
                                        style={{
                                            padding: "5px",
                                            border: "2px solid",
                                            marginTop: "-1px",
                                            float: "right",
                                        }}
                                        className="form-control"
                                        placeholder="Search.."
                                        name="search"
                                        required
                                    />
                                    <button className={classes.srchbtn} type="submit">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </form>

                                {searchStatus ? (
                                    <a
                                        onClick={() => {
                                            setdisplay("inline");
                                            setBackdropOpen(true);
                                            setPageNo(1);
                                            setdirection("Desc");
                                            getAllUserList(1);
                                        }}
                                    >
                                        Clear Search
                                    </a>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <div className="newlisting_headertabdesign">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                            }}
                        >
                            <div>
                                <div className="form-group">
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="newlisting_headertabdesign">
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "50px" }}>SN</th>
                                    <th style={{ minWidth: "190px" }}>
                                        Customer Name <UnfoldMoreIcon onClick={sortUser} id="customer_name" />
                                    </th>
                                    <th style={{ minWidth: "175px" }}>
                                        Email <UnfoldMoreIcon onClick={sortUser} id="sent_to" />
                                    </th>
                                    <th style={{ minWidth: "100px" }}></th>
                                    <th style={{ minWidth: "175px" }}>
                                        Serial No{""}
                                        <UnfoldMoreIcon onClick={sortUser} id="serial_no" />
                                    </th>
                                    <th style={{ minWidth: "175px" }}>
                                        Created Date{" "}
                                        <UnfoldMoreIcon onClick={sortUser} id="created_date" />
                                    </th>
                                    <th style={{ minWidth: "175px" }}>
                                        Purchase Date{" "}
                                        <UnfoldMoreIcon onClick={sortUser} id="purchase_date" />
                                    </th>
                                    <th style={{ minWidth: "175px" }}>
                                        Expiration Date{" "}
                                        <UnfoldMoreIcon onClick={sortUser} id="warranty_expiration_date" />
                                    </th>

                                    <th style={{ minWidth: "175px" }}>
                                        IP Address <UnfoldMoreIcon onClick={sortUser} id="ip_address" />
                                    </th>
                                    <th style={{ minWidth: "175px" }}>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {certificateData != null ? (
                                    certificateData &&
                                    certificateData.map((cert, index) => (
                                        <tr>
                                            {/* countNumber * rows + */}
                                            <th>{countNumber * rows + 1 + index}</th>
                                            <td>
                                                {cert.customer_name}
                                            </td>
                                            <td>
                                                <a style={{ cursor: "pointer", color: "blue" }}>
                                                    {cert.sent_to}
                                                </a>
                                            </td>
                                            <td>
                                                <button style={{ backgroundColor: "#2874f0", color: "#fff", padding: "5px 10px", fontSize: "10px", border: "none" }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        createModalMail(e, cert);
                                                    }}>
                                                    Reset Mail
                                                </button>
                                            </td>
                                            <td>{cert.serial_no}</td>
                                            <td>
                                                {/* {cert.created_date} */}
                                                {cert.created_date
                                                    ? moment(cert.created_date.split(".")[0]).format(
                                                        "YYYY-MM-DD"
                                                    )
                                                    : null}
                                            </td>
                                            <td>
                                                {cert.purchase_date}
                                                {/* {cert.purchase_date
                                                    ? moment(cert.purchase_date.split(".")[0]).format(
                                                        "DD MMM YYYY"
                                                    )
                                                    : null} */}
                                            </td>
                                            <td>
                                                {cert.warranty_expiration_date}
                                                {/* {cert.warranty_expiration_date
                                                    ? moment(cert.warranty_expiration_date.split(".")[0]).format(
                                                        "DD MMM YYYY"
                                                    )
                                                    : null} */}
                                            </td>

                                            <td>{cert.ip_address}</td>
                                            {/* <td>{user.status == true ? "Active" : "InActive"}</td> */}
                                            <td>
                                                <div style={{ display: "flex", gap: "8px" }}>
                                                    {/* <Base64Downloader  base64={`data:application/pdf;base64,${cert.certificate_file}`} downloadName="Warranty Certificate" style={{ backgroundColor: "rgb(7 170 62)", color: "#fff", padding: "5px 10px", fontSize: "10px", border: "none" }}>
                                                        Download
                                                    </Base64Downloader> */}

                                                    <button id={cert.id} style={{ backgroundColor: "rgb(7 170 62)", color: "#fff", padding: "5px 10px", fontSize: "10px", border: "none" }}
                                                        onClick={() => {
                                                            showPdfFile(cert);
                                                            setId(cert.id)
                                                        }}>
                                                        {/* <Base64Downloader id={cert.id} base64={`data:application/pdf;base64,${dnloadpdf}`} downloadName="Warranty Certificate">
                                                     Download  
                                                     </Base64Downloader>     */}
                                                        Download
                                                    </button>


                                                    <button style={{ backgroundColor: "#ff0000", color: "#fff", padding: "5px 10px", fontSize: "10px", border: "none" }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            deleteUser(e, cert);
                                                        }}>
                                                        Delete</button>
                                                    <Modal
                                                        ariaHideApp={false}
                                                        isOpen={deleteOpen}
                                                        onRequestClose={closedModal}
                                                        style={customStyles}
                                                        //   style={{padding:"30px 40px"}}
                                                        contentLabel="Example Modal"
                                                    >

                                                        <h5>
                                                            Are you sure you want to delete current record ?
                                                        </h5>
                                                        <div style={{ float: "right" }}>
                                                            <Button
                                                                color="danger"
                                                                onClick={deleteCurrentCategory}
                                                            >
                                                                Delete
                                                            </Button>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <Button
                                                                color="secondary"
                                                                onClick={() => setDelete(false)}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                    </Modal>
                                                    <button style={{ backgroundColor: "#071a52", color: "#fff", padding: "5px 10px", fontSize: "10px", border: "none" }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            ResentMail(e, cert);
                                                        }}>
                                                        Resend Mail</button>
                                                    <Modal
                                                        ariaHideApp={false}
                                                        isOpen={changeMailOpen}
                                                        onRequestClose={closeModalofMail}
                                                        style={customStyles}
                                                        contentLabel="Example Modal"
                                                    >
                                                        <div className='modalContainer'>
                                                            <form onSubmit={handleSubmit(createChangeMail)} id="addform">
                                                                <h1>Reset Mail</h1>
                                                                <div>
                                                                    <div style={{ marginTop: "20px" }}>

                                                                        <h3 >Email<span >*</span></h3>
                                                                        <input
                                                                            type="text"
                                                                            className="all-input"
                                                                            name="sent_to"
                                                                            {...register("sent_to", {
                                                                                required: "Required",
                                                                            })}
                                                                        />
                                                                        {errors.sent_to && (
                                                                            <p className="marginzero">Email is required.</p>
                                                                        )}
                                                                    </div>

                                                                    <div style={{ display: "flex", justifyContent: "space-between", margin: "30px 0px" }}>
                                                                        <button className='modalbtn backbtn' onClick={() => setChangeMailOpen(false)}>Back</button>
                                                                        <button className='modalbtn submitbtn' >Submit</button>
                                                                    </div>

                                                                </div>
                                                            </form>
                                                            <div>
                                                            </div>
                                                            <br />
                                                        </div>
                                                    </Modal>
                                                </div>

                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <p className={classes.headertekst}>{null}</p>
                                )}
                            </tbody>
                        </Table>
                        <div>
                            <Row>
                                <Col md={4}>
                                    {certificateData?.length ? (
                                        <p style={{ marginTop: "15px" }}>
                                            {countNumber * rows + 1} to{" "}
                                            {countNumber * rows + numberOfElements} of {totalcount}{" "}
                                            records
                                        </p>
                                    ) : (
                                        <p className="formfooterrecordstyle">No Record Found</p>
                                    )}
                                </Col>
                                <Col md={4} style={{ textAlign: "center" }} >



                                </Col>
                                <Col md={4}>
                                    <div className={classes.root}>
                                        {/* {searchStatus || datefilter ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={submitSearch}
                  />
                ) : ( */}
                                        <Pagination
                                            page={paginationPageNo}
                                            count={getPageNo}
                                            onChange={handlepage}
                                        />
                                        {/* )} */}
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                <Col md={4} style={{ textAlign: "center" }} >
                                    <div>

                                        <h5>Download all issued warranty certificate csv</h5>

                                        <div>
                                            {dataForDownload != null ? (
                                                <>
                                                    <button className="dwn_btn"
                                                        onClick={() => setOpen(true)}
                                                    >
                                                        Download
                                                    </button>
                                                    <CSVLink
                                                        data={dataForDownload}
                                                        filename="issued_warranty_certificate csv.csv"
                                                        className="hidden"
                                                        ref={csvLink}
                                                        target="_blank" />
                                                </>
                                            ) : null}

                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WarrantyCertificate;
