import { createSlice } from "@reduxjs/toolkit";

export const empSlice = createSlice({
  name: "emp",
  initialState: {
    employee: null,
    dashboardCheck: 0,
    EmpIpAddress: 0,
    onBreak: 0,
  },
  reducers: {
    login: (state, action) => {
      state.employee = action.payload;
    },
    logout: (state) => {
      state.employee = null;
      state.dashboardCheck = null;
      state.EmpIpAddress = null;
    },
    setDashboardCheck: (state, action) => {
      state.dashboardCheck = action.payload;
    },
    removeDashboardCheck: (state) => {
      state.dashboardCheck = null;
    },
    setEmpIpAddress: (state, action) => {
      state.EmpIpAddress = action.payload;
    },
    removeEmpIpAddress: (state) => {
      state.EmpIpAddress = null;
    },
  },
});

export const {
  login,
  logout,
  setDashboardCheck,
  removeDashboardCheck,
  setEmpIpAddress,
} = empSlice.actions;

export const selectEmployee = (state) => state.emp.employee;
export const selectSessionId = (state) => state.emp.sessionId;
export const selectDashboardCheck = (state) => state.dashboardCheck;
export const selectOnBreak = (state) => state.emp.onBreak;

export default empSlice.reducer;
