import React, { useState } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import { Switch, Route, Link, NavLink, Redirect, HashRouter, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "./../../Images/Amcrestlogo.png";
import { useForm } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from '@mui/material/Backdrop';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { login, company, selectEmployee, selectCompany, setEmpIpAddress, setStarted, setStopped, setSessionIdData, setOnBreak } from "./../features/empSlice";


function LoginPage(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [inputtype, setinputtype] = useState("password");
    let history = useHistory();
    const dispatch = useDispatch();
    const [backdropOpen, setBackdropOpen] = useState(false);
    const { addToast } = useToasts();


    const onSubmit = (data) => {
        setBackdropOpen(true);
        let email = data.email.trim();
        let password = data.password.trim();

        let authenticateData = {
            email,
            password,
        };
        fetch(base_url["api1"] + "/document/login", {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(authenticateData),
        })
            .then((r) => r.json())
            .then((result) => {
                console.log(result);
                if (result.status_code === 200) {

                    addToast(result.message, {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    dispatch(
                        login({
                            token: result.token,
                            emp_email: result.user.email,
                            username: result.user.username,
                            emp_name: result.user.first_name + " " + result.user.last_name,
                        })
                    );
                    localStorage.setItem("JWTToken", result.token);
                    setBackdropOpen(false);
                    history.push(`/`);
                } else if (result.status_code === 400) {
                    setBackdropOpen(false);
                    addToast(result.message, {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    console.log('Login Error');
                } else {
                    setBackdropOpen(false);
                    addToast(result.message, {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    console.log('Wrong Id & pass');
                }
            });

        axios
            .get("https://geolocation-db.com/json/")
            .then(
                (res) => {
                    let ipaddress = res.data.IPv4;
                    localStorage.setItem("EmpIpAddress", res.data.IPv4);
                },
            )
    };

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const openForgotPassModal = (elem) => {
        setIsOpen(true);
    };

    const goBack = () => {
        history.push("/forgotpage");
    };

    const closeModal = () => {
        setIsOpen(false);
    };





    return (
        <>
            <div style={{ backgroundColor: "#071A52", height: "100vh", boxSizing: "border-box" }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdropOpen}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div class="container forget-password">
                    <div class="row">
                        <div class="col-md-12 col-md-offset-4">
                            <div class="panel panel-default">
                                <div class="panel-body">
                                    <div class="text-center">
                                        <img src={logo} alt="car-key" border="0" style={{ width: "400px" }} />
                                        {/* <h2 class="text-center">Login</h2>
                                        <p>You can Login here.</p> */}

                                        <form id="register-form" action="" method="post" onSubmit={handleSubmit(onSubmit)}>
                                            <div class="form-group" style={{ marginTop: "40px", marginBottom: "20px" }}>
                                                <div class="input-group">
                                                    <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
                                                    <input
                                                        id="forgetAnswer"
                                                        placeholder="Email"
                                                        class="form-control"
                                                        style={{ width: "100%" }}
                                                        {...register("email", { required: true })}
                                                        type="email" />
                                                    {errors.email && (
                                                        <p className="errormessage">Username is Required</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div class="form-group" style={{ marginBottom: "20px" }}>
                                                <div class="input-group" style={{ marginBottom: "20px" }}>
                                                    <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
                                                    <input
                                                        id="forgetAnswer"
                                                        placeholder="Password"
                                                        type={inputtype}
                                                        class="form-control"
                                                        style={{ width: "100%" }}
                                                        {...register("password", { required: true })}
                                                    />
                                                    {errors.password && (
                                                        <p className="errormessage">Password is Required</p>
                                                    )}
                                                    {inputtype === "password" ? (
                                                        <i className="fa fa-eye-slash fonticonpositioning" style={{
                                                            cursor: "pointer", position: "absolute",
                                                            right: "7px", top: "23%", fontSize: "1.4em", color: "#94b2c6",
                                                        }} onClick={() => setinputtype("text")} aria-hidden="true"></i>
                                                    ) : (
                                                        <i className="fa fa-eye fonticonpositioning" style={{
                                                            cursor: "pointer", position: "absolute",
                                                            right: "7px", top: "23%", fontSize: "1.4em", color: "#94b2c6",
                                                        }} onClick={() => setinputtype("password")} aria-hidden="true"></i>
                                                    )}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <input name="btnForget" class="btn btn-lg btn-primary btn-block btnForget" value="Login" type="submit" />
                                            </div>
                                            <h5 className="forgotclass">
                                                <nav>
                                                    {/* <p style={{cursor:"pointer"}} onClick={openForgotPassModal}>Forgot Password?</p> */}
                                                    <Link onClick={goBack} className="forget-password-link">Forgot password</Link>
                                                </nav>
                                            </h5>
                                            {/* <p className="forgotclass">
                                             Don't have an account? <a href="/Sign-up">Signup</a>
                                            </p> */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default LoginPage;
