import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import base_url from "./../api/bootapi";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectEmployee } from "./../components/features/empSlice";
import { useToasts } from "react-toast-notifications";


const Forgot = () => {


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useHistory();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [forgotUser, setForgotUser] = useState(false);
  const forgotPassword = (e) => {
    // console.log(e.target[0].value);
    axios
      .get(`${base_url.api1}/document/forgot_password?email=` + e.target[0].value.trim(), {
      })
      .then(
        (response) => {
          console.log(response);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Password Reset Link Send to Your Email',
            showConfirmButton: false,
            timer: 2000
          })
        },
        (error) => {
          if (error.response.status == "401") {
            dispatch(logout());
            history.push("/");
            addToast(` Session Expired.`, {
              appearance: "error",
              autoDismiss: true,
            });
          }
          else {
            setForgotUser(true);
          }
        }
      );
  };

  const goBack = () => {
    history.push("/");
  };

  return (
    <div class="me">
      <div class="page-wrap d-flex flex-row align-items-center">
        <div class="container12" style={{ marginTop: "-400px" }}>
          <div class="row">
            <div class="col-md-3">
              <img src="https://scocre.com/assets/img/forgot.png" class="img-fluid" alt="" />
            </div>
            <div class="col-md-9">
              <h2 class="font-weight-light">Forgot your password?</h2>
              Not to worry. Just enter your email address below and we'll send you an instruction email for recovery.
              <form target="_blank"
                onSubmit={(e) => {
                  e.preventDefault();
                  forgotPassword(e);
                }}
                class="mt-3">
                <input
                  class="form-control form-control-lg"
                  type="email"
                  placeholder="Your email address"
                  {...register("email", {
                    required: "Required",
                  })}
                />




                <div class="text-right my-3">
                  <button type="submit" class="btn btn-lg btn-danger" onClick={goBack}>Go Back</button>
                  <button type="submit" class="btn btn-lg btn-success">Reset Password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Forgot