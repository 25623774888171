import React, { useState } from "react";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import "./../css/Password.css";
import base_url from '../../api/bootapi';
import { ToastContainer, toast } from "react-toastify";
import Swal from 'sweetalert2';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo from "./../../Images/Amcrestlogo.png";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectEmployee } from "../../components/features/empSlice";
import { useToasts } from "react-toast-notifications";


function ForgotPassword() {
  let params = useParams();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  console.log(params);
  const history = useHistory();
  const [details, setDetails] = useState({ email: "", password: "" });
  const [inputType, setInputType] = useState("password");
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const forgotPassword = (e) => {
    console.log(e.target[0].value);
    console.log(e.target[1].value);
    let data = {
      email: e.target[0].value.trim(),
      password: e.target[1].value.trim(),
    }
    console.log(data);
    setBackdropOpen(true);
    axios
      .post(`${base_url.api1}/document/forgot_password_change`, data, {
        headers: {
          Authorization: "JWT " + params.token.trim(),
        },
      })
      .then((response) => {
        // toast.success("Password Reset Successful");
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Password Reset Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        setBackdropOpen(false);
        history.push(`/`);
      })
      .catch((error) => {
        // toast.error("Token Error");

        if (error.response.status == "401") {
          dispatch(logout());
          history.push("/");
          addToast(` Session Expired.`, {
            appearance: "error",
            autoDismiss: true,
          });
        }
        else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Token Error',
            showConfirmButton: false,
            timer: 1500
          })
          setBackdropOpen(false);
        }
      })
  }


  const goBack = () => {
    history.push("/forgotpage");
  };

  return (
    <>
      {/* <div>
      <Backdrop open={backdropOpen}
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
    <div className="loginform">
      <ToastContainer />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          forgotPassword(e);
        }}
      >
        <div className="form-inner forgot_form_width">
          <h2 className="forgot_form_head">Forgot Password</h2>
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              className="form-control underline-input forminput2"
              value={params.email}
              readOnly
            />
          </div>
          <div className="form-group mt-10">
            <label>New Password</label>
            <div style={{ position: "relative" }}>
              <input
                type={inputType}
                className="form-control underline-input forminput2"
                name="new_password"
                id="new_password"
                placeholder="Password"
                onChange={(e) =>
                  setDetails({ ...details, new_password: e.target.value })
                }
              />
              {inputType === "password" ? (
                <i
                  className="fa fa-eye-slash fonticonpositioning"
                  onClick={() => setInputType("text")}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className="fa fa-eye fonticonpositioning"
                  onClick={() => setInputType("password")}
                  aria-hidden="true"
                ></i>
              )}
            </div>
          </div>
          <div className="form-group text-center mt-40">
            <input
              type="submit"
              className="btn btn-full btn-dutch b-0 br-5 text-uppercase login_access"
              value="Submit"
            />
          </div>
        </div>
      </form>
    </div>
    </div> */}
      <div style={{ backgroundColor: "#071A52", height: "100vh", boxSizing: "border-box" }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div class="container forget-password">
          <div class="row">
            <div class="col-md-12 col-md-offset-4">
              <div class="panel panel-default">
                <div class="panel-body">
                  <div class="text-center">
                    {/* <img src={logo} alt="car-key" border="0" style={{ width: "400px" }} /> */}
                    <img src="https://usa.afsglobal.org/SSO/SelfPasswordRecovery/images/send_reset_password.svg?v=3" alt="car-key" border="0"></img>
                    <h2 class="text-center">Forgot Password?</h2>
                    <p>You can reset your password here.</p>
                    <form id="register-form"
                      method="post"
                      onSubmit={(e) => {
                        e.preventDefault();
                        forgotPassword(e);
                      }}
                    >
                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
                          <input
                            id="forgetAnswer"
                            placeholder="Email"
                            class="form-control"
                            style={{ width: "100%" }}
                            {...register("email", { required: true })}
                            type="email" />
                          {errors.email && (
                            <p className="errormessage">Email is Required</p>
                          )}
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
                          <input
                            id="forgetAnswer"
                            placeholder="New Password"
                            class="form-control"
                            style={{ width: "100%" }}
                            {...register("password", { required: true })}
                            type="text" />
                          {errors.password && (
                            <p className="errormessage">New Password is Required</p>
                          )}
                          {/* {inputtype === "password" ? (
                                              <i className="fa fa-eye-slash fonticonpositioning" style={{cursor:"pointer",  position: "absolute",
                                              right: "7px",top:"50%", fontSize:"1.4em", color: "#94b2c6",}} onClick={() => setinputtype("text")} aria-hidden="true"></i>
                                            ) : (
                                              <i className="fa fa-eye fonticonpositioning" style={{cursor:"pointer",  position: "absolute",
                                              right: "7px",top:"50%", fontSize:"1.4em", color: "#94b2c6",}} onClick={() => setinputtype("password")} aria-hidden="true"></i>
                                            )} */}
                        </div>
                      </div>
                      <div class="form-group">
                        <input name="btnForget" class="btn btn-lg btn-primary btn-block btnForget" value="Reset Password" type="submit" />
                      </div>
                      <h5 className="forgotclass">
                        <nav>
                          {/* <p style={{cursor:"pointer"}} onClick={openForgotPassModal}>Forgot Password?</p> */}
                          <Link onClick={goBack} className="forget-password-link">Back To Previous Page</Link>
                        </nav>
                      </h5>
                      {/* <p className="forgotclass">
                                             Don't have an account? <a href="/Sign-up">Signup</a>
                                            </p> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default ForgotPassword;
