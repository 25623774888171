import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, NavLink, Redirect, HashRouter, useHistory } from "react-router-dom";
import "./components/css/LoginForm.css";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import axios from "axios";
import NewDash from "./components/DashBoard/NewDash";
import base_url from "./api/bootapi";
import Backdrop from '@mui/material/Backdrop';
import Swal from 'sweetalert2'
import CircularProgress from "@mui/material/CircularProgress";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { login, company, selectEmployee, selectCompany, setEmpIpAddress, setStarted, setStopped, setSessionIdData, setOnBreak } from "./components/features/empSlice";
import Modal from "react-modal";
import { Col, Row, Button } from "reactstrap";
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import { useDispatch, useSelector } from "react-redux";
import LoginPage from "./components//LoginPage/LoginPage";
import WarrentyCertificate from './components/WarrantyCertificate/WarrantyCertificate';
import RegisterCertificate from './components/RegisterCertificate/Certificate';
import Forgot from "./components/Forgot";
import logo from "./Images/Amcrestlogo.png";





const customStyles2 = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    maxWidth: "900px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
};





function App() {
  useEffect(() => {
  }, []);




  // console.log(window.location.href); 

  const employee = useSelector(selectEmployee);
  console.log(employee);

  return (
    <ToastProvider>
      <div>

        {!employee ? (
         <Switch>
         <Route exact path="/">
           <LoginPage />
         </Route>
         <Route exact path="/forgotpage">
            <Forgot />
         </Route>
         <Route exact path="/reset-password/:token/:email">
         <ForgotPassword/>
         </Route>
       </Switch>
        ) : (
          <Switch>
        <Route exact path="/register">
           <RegisterCertificate/>
         </Route>
            <Route exact path="/" >
              <NewDash sidebarvalue={"dashboard"} Comp={WarrentyCertificate} />
            </Route>
          </Switch>
        )}
      </div>
    </ToastProvider>
  );
}

export default App;